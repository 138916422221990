.filepond--container {
    max-width: 1000px;
    margin: auto;
    padding: 20px;
}

.filepond--root,
.filepond--root .filepond--drop-label {
    height: 200px;
}

.filepond--item {
    height: 160px;
    margin: auto;
}

.filepond--file {
    align-items: center !important;
}

.filepond--drop-label > label {
    font-size: 24px !important;
    font-weight: 600 !important;
}

.filepond-label {
    font-size: 22px !important;
}

.filepond-label-second {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.filepond--file-info  {
    font-size: 22px !important;
    font-weight: 600 !important;
}

.filepond-extension-label {
    margin-top: 15px !important;
    font-size: 14px !important;
    color: gray;
}